// 查询分类配置列表
const queryListURL = `${API_CONFIG.baseURL}smDeviceAction!categoryManageList.action`
// 删除子分类
const deleteURL = `${API_CONFIG.baseURL}smDeviceAction!delCategory.action`
// 导出分类配置
const exportURL = `${API_CONFIG.baseURL}smDeviceAction!exportCategoryToExcel.action`
// 新增子分类
const addURL = `${API_CONFIG.baseURL}smDeviceAction!saveCategory.action`
// 编辑子分类
const editURL = `${API_CONFIG.baseURL}smDeviceAction!updateCategory.action`
// 查询子分类
const queryDetailURL = `${API_CONFIG.baseURL}smDeviceAction!queryOneCategory.action`
export {
  queryListURL,
  deleteURL,
  exportURL,
  addURL,
  editURL,
  queryDetailURL
}
