<template>
  <div class="classify-config-list-container">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="返回" @click="goBack" />
      </template>
      <template #searchSlot>
        <v-input label="分类名称" v-model="searchParams.name" />
        <v-input label="分类编码" v-model="searchParams.code" />
        <v-input label="上级分类" v-model="searchParams.superName" />
        <v-datepicker label="创建时间段" :startTime.sync="searchParams.startDate" :endTime.sync="searchParams.endDate"></v-datepicker>
        <v-input label="创建人" v-model="searchParams.userName" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="scope.row.codeLevel === 1 || scope.row.codeLevel === 2">
              <v-button text="新增子类" type="text" permission="add" @click="add(scope.row)" />
            </el-dropdown-item>
            <el-dropdown-item v-if="scope.row.codeLevel === 2 || scope.row.codeLevel === 3">
              <v-button text="删除" type="text" permission="delete" @click="deleteClassify(scope.row)" />
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </list>
  </div>
</template>

<script>
import { queryListURL, exportURL, deleteURL } from './api'

export default {
  name: 'DeviceConfigClassifyList',
  data () {
    return {
      searchUrl: queryListURL,
      exportUrl: exportURL,
      searchParams: {
        name: '',
        code: '',
        superName: '',
        startDate: '',
        endDate: '',
        userName: ''
      },
      headers: [
        {
          prop: 'name',
          label: '分类名称'
        },
        {
          prop: 'code',
          label: '分类编码'
        },
        {
          prop: 'superName',
          label: '上级分类'
        },
        {
          prop: 'codeLevel',
          label: '级别'
        },
        {
          prop: 'userName',
          label: '创建人'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  created () {
    this.$setBreadcrumb('配置分类')
  },
  methods: {
    // 关联通用内容
    add (row) {
      this.$router.push({
        name: 'deviceConfigClassifyConfigAdd',
        query: {
          superId: row.id,
          superName: row.name,
          codeLevel: row.codeLevel
        }
      })
    },
    edit (row) {
      this.$router.push({
        name: 'deviceConfigClassifyConfigEdit',
        query: {
          id: row.id
        }
      })
    },
    async deleteClassify (row) {
      let isConfirm = await this.$confirm('确认删除？')
      if (isConfirm) {
        let data = await this.$axios.post(deleteURL, { id: row.id, codeLevel: row.codeLevel, dataObject: row.name }, {
          hideErrorMessage: true
        })
        if (Number(data.status) === 100) {
          this.$refs.list.searchData()
        } else {
          let isConfirm = await this.$confirm(data.msg, {
            confirmButtonText: '查看设备',
            cancelButtionText: '取消',
            dangerouslyUseHTMLString: true,
            title: '提示',
            type: 'warning'
          })
          if (isConfirm) {
            let params = {}
            if (row.codeLevel === 2) {
              params.secondLevel = row.id
            } else if (row.codeLevel === 3) {
              params.thirdLevel = row.id
            }
            this.$router.push({
              name: 'device1',
              query: params
            })
          }
        }
      }
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
